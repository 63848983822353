import React from 'react';

import { Container } from 'react-bootstrap';

export default () => (

  <Container className="my-3">
    <h1>About</h1>

    <p>
      ForgeServ is a
      {' '}
      <b>private</b>
      , invite-only Server Platform that is self-hosted and runs all types of servers from
      Vanilla, Paper, Forge & Fabric based on whatever piques the interest of its players.
      Generally only Friends or Friends-of-Friends are invited to join the ForgeServ community.
      Aside from direct &amp; indirect friendship, the remaining members were invited to join
      through regularly chatting and providing helpful or productive conversation over in my
      {' '}
      <a href="https://twitch.tv/oitsjustjose" alt="Twitch">Twitch Chat</a>
      {' '}
      or through chatting on Twitter 🙂
    </p>
  </Container>
);
