import React from 'react';
import { Container } from 'react-bootstrap';

export default () => (
  <Container style={{ background: 'transparent' }}>
    <div className="embed-responsive embed-responsive-16by9 w-100" style={{ aspectRatio: '16/9' }}>
      <iframe
        title="https://dashboard.forgeserv.net"
        className="embed-responsive-item w-100 h-100"
        style={{ borderRadius: '8px' }}
        src="https://dashboard.forgeserv.net"
      />
    </div>
  </Container>
);
