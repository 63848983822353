/* eslint-disable import/prefer-default-export */

export default async () => {
  const resp = await fetch('https://api.forgeserv.net');
  if (resp.status !== 200) {
    // eslint-disable-next-line no-console
    return { error: await resp.text() };
  }

  // Filter out closed servers using .filter((x) => x.status.toLowerCase() !== 'exited');
  const runningServers = await resp.json();
  runningServers.sort((a, b) => {
    const { name: aName, status: aStatus, health: aHealth } = a;
    const { name: bName, status: bStatus, health: bHealth } = b;

    /* Prioritize ForgeServ always :) */
    if ([aName, bName].includes('ForgeServ')) {
      return aName === 'ForgeServ' ? -1 : 1;
    }
    if (aStatus === bStatus) {
      if (aHealth === bHealth) {
        if (aName === bName) return 0;
        if (aName > bName) return 1;
        return -1;
      }
      return aHealth > bHealth ? 1 : -1;
    }
    return aStatus < bStatus ? 1 : -1;
  });

  // Sort server players because sometimes the API shuffles them
  // As long as they're sorted, the equality check won't fail incorrectly
  runningServers.forEach((server) => server.players.sort((a, b) => a.name > b.name));
  return runningServers;
};
