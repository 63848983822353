import React from 'react';

import { Container, Spinner } from 'react-bootstrap';

export default () => (
  <Container>
    <h3>Loading</h3>
    <Spinner />
  </Container>
);
