import React, { useState } from 'react';
import { TbFaceIdError } from 'react-icons/tb';
import { Button, Collapse, Container } from 'react-bootstrap';

export default ({ errorTxt }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <h1>
        <TbFaceIdError />
        {' '}
        All Server Instances:
      </h1>
      <p>Failed to load server instances from ForgeServ:</p>
      <Button onClick={() => setOpen(!open)} variant="danger">
        {open ? 'Hide Errors' : 'Show Errors'}
      </Button>
      <Collapse className="my-4" in={open}>
        <div className="error-text">
          <code className="p-2">{errorTxt}</code>
        </div>
      </Collapse>
    </Container>
  );
};
