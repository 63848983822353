import React, { useState } from 'react';
import { Image, Nav } from 'react-bootstrap';
import { GiServerRack } from 'react-icons/gi';
import { SiGrafana, SiLinuxcontainers } from 'react-icons/si';
import { TbInfoSquareRoundedFilled } from 'react-icons/tb';
import About from '../containers/About';
import Graphs from '../containers/Graphs';
import ServerGrid from '../containers/ServerGrid';
import Specs from '../containers/Specs';
import logo from '../img/logo.png';

const tabs = {
  Servers: (<ServerGrid />),
  Dashboard: (<Graphs />),
  Specs: (<Specs />),
  About: (<About />),
};

export default () => {
  const [tabKey, setTabKey] = useState(window.localStorage.getItem('last-tab') || 'About');

  const onSelect = (eventKey) => {
    setTabKey(eventKey);
    window.localStorage.setItem('last-tab', eventKey);
  };

  return (
    <div>
      <div className="masthead">
        <div className="text-center py-3">
          <div className="masthead-title">
            <Image src={logo} alt="logo" />
            <h1>ForgeServ</h1>
          </div>
          <h6>
            A server platform & community revolving around Minecraft Modpacks + Servers
          </h6>

          <Nav
            className="flex-column flex-sm-row"
            variant="pills"
            defaultActiveKey={tabKey}
            onSelect={onSelect}
          >
            <Nav.Item>
              <Nav.Link eventKey="Servers">
                <SiLinuxcontainers />
                {' '}
                Servers
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Dashboard">
                <SiGrafana />
                {' '}
                Dashboard
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Specs">
                <GiServerRack />
                {' '}
                Specs
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="About">
                <TbInfoSquareRoundedFilled />
                {' '}
                About
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>

      <div className="my-3 forgeserv-active">
        {tabs[tabKey]}
      </div>
    </div>
  );
};
