import React from 'react';
import { Container } from 'react-bootstrap';
import { FaMemory } from 'react-icons/fa';
import { FiCpu, FiHardDrive } from 'react-icons/fi';
import { LuDatabaseBackup } from 'react-icons/lu';

export default () => (
  <Container className="my-3">
    <h1>The Server&apos;s Hardware</h1>
    <h6>ForgeServ is hosted myself on a Fiber-Internet backend. Tech Specs, for the nerds:</h6>

    <div>
      <FiCpu />
      {' '}
      12C/24T AMD Ryzen 9 7900X
    </div>
    <div>
      <FaMemory />
      {' '}
      128GB of System Memory
    </div>
    <div>
      <FiHardDrive />
      {' '}
      2TB PCIe-Gen4 nVME SSD
    </div>
    <div>
      <LuDatabaseBackup />
      {' '}
      Weekly Backups to 24TB NAS
    </div>
  </Container>
);
