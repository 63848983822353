import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Container,
  Image,
  Row,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { FaMapMarkedAlt } from 'react-icons/fa';
import Error from '../components/Error';
import Loading from '../components/Loading';
import GetServers from '../data/api';
import useInterval from '../hooks/useInterval';
import defaultServerIcon from '../img/default-server.png';
import stoppedServerIcon from '../img/stopped-server.png';

const getServerColor = ({ status, health }) => {
  const cols = {
    good: '#a3be8c',
    moderate: '#ebcb8b',
    bad: '#d08770',
    severe: '#bf616a',
  };

  if (status.toLowerCase() === 'exited') {
    return cols.severe;
  } if (health.toLowerCase() === 'starting') {
    return cols.moderate;
  } if (health.toLowerCase() === 'unhealthy') {
    return cols.bad;
  }
  return cols.good;
};

const isHealthy = ({ status, health }) => (
  status.toLowerCase() === 'running'
  && health.toLowerCase() === 'healthy'
);

const getFallbackIcon = (x) => {
  if (x.status === 'exited') {
    return stoppedServerIcon;
  }
  return defaultServerIcon;
};

const DynmapPopover = (
  <Popover title="Dynmap" className="dynmap-popover">
    Click to open the Server&apos;s Map!
  </Popover>
);

export default () => {
  const [servers, setServers] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [error, setError] = useState(null);

  const update = async (setHasFetchedIn, serversIn, setServersIn, setErrorIn) => {
    const running = await GetServers();
    setHasFetchedIn(true);
    if (running.error) {
      setErrorIn(running.error);
      setServersIn(null);
      return;
    }
    // Don't re-render if the data is the same
    if (JSON.stringify(serversIn) === JSON.stringify(running)) return;
    setServersIn(running);
  };

  useInterval(async () => { await update(setHasFetched, servers, setServers, setError); }, 1000);

  useEffect(() => {
    if (hasFetched) return () => {};
    update(setHasFetched, servers, setServers, setError);
    return () => {};
  }, [hasFetched, setHasFetched, servers, setServers, setError]);

  if (!servers && hasFetched) {
    return (<Error errorTxt={error} />);
  }

  if (!servers && !hasFetched) {
    return (<Loading />);
  }

  return (
    <Container style={{ background: 'transparent' }}>
      <Row xs={1} md={2} className="g-4">
        {servers.map((x) => (
          <Col key={x.name}>
            <Card style={{ backgroundColor: `${getServerColor(x)}AF`, border: `2px solid ${getServerColor(x)}` }}>
              <Card.Title className="server-card-title">
                <Image
                  rounded
                  className="server-icon"
                  src={x.icon ? `data:image/png;base64,${x.icon}` : getFallbackIcon(x)}
                  alt={`${x.name} server icon`}
                />
                <div className="server-title">
                  {isHealthy(x) && x.motd ? x.motd : x.name}
                  {x.dynmap && (
                    <OverlayTrigger overlay={DynmapPopover} placement="top">
                      <a
                        href={x.dynmap}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="dynmap"
                        className="dynmap"
                      >
                        <FaMapMarkedAlt />
                      </a>
                    </OverlayTrigger>
                  )}
                </div>
              </Card.Title>
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col>
                      {/* Paper shows up in the **version** too, parse and clean it out */}
                      <h6>{`Version: ${x.type} ${x.version.toLowerCase().replace(/paper/g, '')}`}</h6>
                      <div>{`Status: ${x.status}`}</div>
                    </Col>

                    {isHealthy(x) && (
                    <Col>
                      <h6>{`Players: ${x.online}/${x.max}:`}</h6>
                      <div>
                        {x.players.map(({ name, uuid }) => (
                          <div>
                            <Image
                              style={{ width: '32px', height: 'auto', marginRight: '0.5rem' }}
                              src={`https://cravatar.eu/head/${uuid}`}
                            />
                            {name}
                          </div>
                        ))}
                      </div>
                    </Col>
                    )}
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
